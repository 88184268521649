import { CompletionType } from '../projects/ProjectTypes'

export const PER_ACTION_COLOR = '#712ecc'
export const GROUPED_COLOR = '#168a56'

export const actionColor = (completionType: CompletionType) => {
    switch (completionType) {
        case 'PER':
            return PER_ACTION_COLOR
        case 'GROUPED':
            return GROUPED_COLOR
        default:
            throw new Error('Invalid completion type')
    }
}
