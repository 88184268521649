import { Contact } from "../../contacts/ContactTypes";
import { Conversation } from "../../conversations/ConversationTypes";
import { Action } from "../../projects/ProjectTypes";
import { UserType } from "../../users/UserTypes";
import ContactFormContainer from "./ContactFormContainer";
import ConversationFormContainer from "./ConversationFormContainer";

type ActionContainerProps = {
  currentAction: Action;
  currentUser: UserType;
  clearCurrentAction: () => void;
  contacts: Array<Contact>;
  refreshActionState: () => void;
  conversations: Array<Conversation>;
  tourEnabled: boolean;
};

export default function ActionContainer({
  currentAction,
  currentUser,
  clearCurrentAction,
  contacts,
  conversations,
  refreshActionState,
  tourEnabled,
}: ActionContainerProps) {
  const render = () => {
    switch (currentAction.type) {
      case "COLLECT_CONTACTS":
        return (
          <ContactFormContainer
            currentAction={currentAction}
            currentUser={currentUser}
            clearCurrentAction={clearCurrentAction}
            contacts={contacts}
            refreshActionState={refreshActionState}
            tourEnabled={tourEnabled}
          />
        );
      case "CONVERSATIONS":
        return (
          <ConversationFormContainer
            currentAction={currentAction}
            currentUser={currentUser}
            clearCurrentAction={clearCurrentAction}
            contacts={contacts}
            conversations={conversations}
            refreshActionState={refreshActionState}
            tourEnabled={tourEnabled}
          />
        );

      default:
        throw new Error("invalid action type");
    }
  };
  return render();
}
