import { CheckIcon } from '@mantine/core'
import HomeActionComponentTitle from './HomeActionComponentTitle'

type HomeActionComponentCompletedProps = {
    actionType: string
    actionName: string
    payAmount: string
    completionDate?: string
}
export default function HomeActionComponentCompleted({
    payAmount,
    completionDate,
}: HomeActionComponentCompletedProps) {
    return (
        <>
            <div
                style={{
                    height: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <CheckIcon
                    style={{
                        height: '30px',
                        marginLeft: '10px',
                        marginTop: '15px',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                />
            </div>
            <div
                style={{
                    height: '50%',
                    marginTop: '20px',
                    textAlign: 'center',
                }}
            >
                You completed this action on {completionDate} and earned $
                {payAmount}
            </div>
        </>
    )
}
