import { faBullhorn, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Title } from "@mantine/core";
import { CompletionType } from "../../projects/ProjectTypes";
import { actionColor } from "../colors";

type HomeActionComponentTitleProps = {
  actionType: string;
  actionName: string;
  completionType: CompletionType;
};
export default function HomeActionComponentTitle({
  actionType,
  actionName,
  completionType,
}: HomeActionComponentTitleProps) {
  const icon = () => {
    switch (actionType) {
      case "COLLECT_CONTACTS":
        return faUserPlus;
      case "CONVERSATIONS":
        return faBullhorn;
      default:
        throw new Error("invalid action type");
    }
  };

  return (
    <div
      style={{
        justifyContent: "center",
        width: "100%",
        display: "flex",
        backgroundColor: actionColor(completionType),
        height: "30px",
      }}
      className="title"
    >
      <div style={{ marginTop: "5px" }}>
        <FontAwesomeIcon
          icon={icon()}
          size={"lg"}
          style={{ marginRight: "10px", display: "inline" }}
        />
        <Title order={4} style={{ display: "inline" }}>
          {actionName}
        </Title>
      </div>
    </div>
  );
}
