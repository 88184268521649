import { Select } from "@mantine/core";
import { TourProvider, useTour } from "@reactour/tour";
import { useEffect, useState } from "react";
import { Contact } from "../../contacts/ContactTypes";
import { useNotification } from "../../Contexts";
import { Conversation } from "../../conversations/ConversationTypes";
import {
  contactValidForConversationAction,
  ConversationsAction,
  isValidGroupedAction,
  isValidPerAction,
} from "../../projects/ProjectTypes";
import { UserType } from "../../users/UserTypes";
import { customInputElementOrder } from "../forms/CustomTextInput";
import FormRow from "../forms/FormRow";
import NotificationDisplay from "../NotificationDisplay";
import Tour from "../Tour";
import ConversationForm from "./ConversationForm";
import FormTitle from "./FormTitle";

type ConversationFormContainerProps = {
  currentAction: ConversationsAction;
  currentUser: UserType;
  clearCurrentAction: () => void;
  contacts: Array<Contact>;
  conversations: Array<Conversation>;
  refreshActionState: () => void;
  tourEnabled: boolean;
};

export default function ConversationFormContainer({
  currentAction,
  currentUser,
  clearCurrentAction,
  contacts,
  conversations,
  refreshActionState,
  tourEnabled,
}: ConversationFormContainerProps) {
  const [contact, setContact] = useState<Contact | null>();
  const { steps } = useTour();
  const { showNotification } = useNotification();

  const actionConversations = conversations.filter(
    (conversation: Conversation) => {
      return conversation.action_id === currentAction.id;
    }
  );

  const successfulActionConversations = actionConversations.filter(
    (conversation: Conversation) => {
      return conversation.successful === true;
    }
  );

  const validContacts = contacts.filter((contact) => {
    return (
      successfulActionConversations.find(
        (conv) => conv.successful && conv.contact_id === contact.id
      ) === undefined &&
      contactValidForConversationAction(contact, currentAction)
    );
  });

  const handleContactChange = (value: string | null) => {
    const selectedContact =
      validContacts.find((c) => c.id.toString() === value) || null;
    setContact(selectedContact);
  };

  useEffect(() => {
    if (
      isValidGroupedAction(currentAction) &&
      successfulActionConversations.length >= currentAction.entries_required
    ) {
      showNotification("Congratulations!  You've completed this action.");
      clearCurrentAction();
    }
    if (
      isValidPerAction(currentAction) &&
      successfulActionConversations.length >= currentAction.max_entries
    ) {
      showNotification("Congratulations!  You've completed this action.");
      clearCurrentAction();
    }
    if (validContacts.length === 0) {
      showNotification(
        "You've successfully contacted all your contacts.  Add more to complete this action."
      );
      clearCurrentAction();
    }
  }, [
    conversations,
    currentAction,
    clearCurrentAction,
    successfulActionConversations.length,
    validContacts.length,
  ]);

  const contactOptions = validContacts.map((contact: Contact) => {
    return {
      value: contact.id.toString(),
      label: `${contact.first_name} ${contact.last_name}`,
    };
  });

  return (
    <TourProvider
      steps={steps}
      disableInteraction
      showCloseButton
      styles={{
        close: (base) => ({ ...base, color: "blue", width: "15px" }),
      }}
    >
      <Tour
        tour_enabled={tourEnabled}
        section="conversation_form"
        user_first_name={currentUser?.first_name}
        message="Click here to tour the conversations form."
      />
      <NotificationDisplay />
      <div
        style={{
          padding: "10px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <FormTitle action={currentAction} />
        <FormRow>
          <Select
            id="contact-select"
            value={contact?.id.toString() || ""}
            label="Select a contact"
            data={contactOptions}
            onChange={handleContactChange}
            inputWrapperOrder={customInputElementOrder}
            searchable
            style={{
              width: "100%",
            }}
          />
        </FormRow>

        {contact ? (
          <div>
            <ConversationForm
              key={contact.id}
              contact={contact}
              currentAction={currentAction}
              completeCallback={() => {
                setContact(null);
                refreshActionState();
              }}
            />
          </div>
        ) : null}
      </div>
    </TourProvider>
  );
}
