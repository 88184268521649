import { useMediaQuery } from "@mantine/hooks";

export default function FormRow({ children }: { children: React.ReactNode }) {
  const largeScreen = useMediaQuery("(min-width: 60em)");

  return largeScreen ? (
    <div
      style={{
        display: "flex",
        gap: "10px",
        padding: "10px",
      }}
      className="form-row"
    >
      {children}
    </div>
  ) : (
    <> {children} </>
  );
}
