export default class APIService {
    static csrfToken = () => {
        return document
            .querySelector('meta[name=csrf_token]')
            ?.getAttribute('content')!
    }

    static async getEntity<T>(endpoint: string, id: number) {
        return APIService.get(`${endpoint}/${id}`) as T
    }

    static async get(endpoint: string) {
        return APIService._makeRequest(endpoint, 'GET', null)
    }
    static async getAsJson<T>(endpoint: string) {
        return APIService._makeRequest(endpoint, 'GET', null).then(
            (response) => {
                if (response.ok) {
                    return response.json() as T
                }
            }
        )
    }

    static async create(endpoint: string, entityData: any) {
        return APIService._makeRequest(endpoint, 'POST', entityData)
    }

    static async _makeRequest(
        endpoint: string,
        method: 'POST' | 'GET',
        entityData: any
    ) {
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('token'),
            CSRFToken: APIService.csrfToken(),
            'X-CSRFToken': APIService.csrfToken(),
        }
        return fetch(`/api/${endpoint}`, {
            method,
            headers,
            body: entityData ? JSON.stringify(entityData) : null,
        })
    }

    static async getCSRFToken() {
        return fetch('/getCSRFToken')
    }
}
