import { TourStepType } from "../tour_steps/TourStepTypes";
import APIService from "./ApiService";

export default class TourStepsService {
  static async getTourSteps(section: string) {
    type getTourStepsResponse = {
      tour_steps: Array<TourStepType>;
    };
    return APIService.getAsJson<getTourStepsResponse>(
      `tour_steps/${section}`
    ).then((response) => {
      return response?.tour_steps;
    });
  }
}
