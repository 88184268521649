import { Notification } from "@mantine/core";
import { useNotification } from "../Contexts";

function NotificationDisplay() {
  const { notification } = useNotification();
  if (!notification) {
    return null;
  }
  return (
    <div style={{ marginTop: "5px" }}>
      <Notification color="orange">{notification}</Notification>
    </div>
  );
}

export default NotificationDisplay;
