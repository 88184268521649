import { useState } from "react";
import CustomInputWithOptions from "./CustomInputWithOptions";
import { CustomTextInputParams } from "./CustomTextInput";

type State = {
  name: string;
  value: string;
};

type StateInputProps<T extends Record<string, string>> =
  CustomTextInputParams<T> & {
    placeholder?: string;
    onInput: (event: React.ChangeEvent<HTMLInputElement>) => void;
    states: Array<State>;
  };

export default function StateInput<T extends { state: string }>(
  props: StateInputProps<T>
) {
  const [dropDownOpen, setDropDownOpen] = useState(false);

  return (
    <CustomInputWithOptions
      label="State"
      value={props.value}
      model_attr="state"
      options={props.states.map((state) => {
        return { value: state.value, label: state.name };
      })}
      updateModel={props.updateModel}
      selectProps={{
        onInput: (event: React.ChangeEvent<HTMLInputElement>) => {
          props.onInput(event);
          setDropDownOpen(false);
          event.target.blur();
        },
        dropdownOpened: dropDownOpen,
        onFocus: () => {
          setDropDownOpen(true);
        },
        onBlur: () => {
          setDropDownOpen(false);
        },
      }}
      required
    />
  );
}
