import { Button } from "@mantine/core";
import { TourProvider, useTour } from "@reactour/tour";
import { useEffect, useState } from "react";
import { Contact } from "../../contacts/ContactTypes";
import { useNotification } from "../../Contexts";
import {
  CollectContactsAction,
  isValidGroupedAction,
  isValidPerAction,
} from "../../projects/ProjectTypes";
import { UserType } from "../../users/UserTypes";
import Tour from "../Tour";
import ContactForm from "./ContactForm";
import FormTitle from "./FormTitle";

type ContactFormContainerProps = {
  currentAction: CollectContactsAction;
  currentUser: UserType;
  clearCurrentAction: () => void;
  contacts: Array<Contact>;
  refreshActionState: () => void;
  tourEnabled: boolean;
};

export default function ContactFormContainer({
  currentAction,
  currentUser,
  clearCurrentAction,
  refreshActionState,
  contacts,
  tourEnabled,
}: ContactFormContainerProps) {
  const [contact, setContact] = useState<Contact | null>(null);
  const [page, setPage] = useState(contacts.length + 1);
  const { steps } = useTour();
  const { showNotification } = useNotification();

  useEffect(() => {
    setContact(contacts[page - 1]);
  }, [contacts, page]);

  useEffect(() => {
    setPage(contacts.length + 1);
  }, [contacts]);

  const actionContacts = contacts.filter((contact: Contact) => {
    return contact.action_id === currentAction.id;
  });

  useEffect(() => {
    if (
      isValidGroupedAction(currentAction) &&
      actionContacts.length >= currentAction.entries_required
    ) {
      showNotification("Congratulations!  You've completed this action.");
      clearCurrentAction();
    }
    if (
      isValidPerAction(currentAction) &&
      actionContacts.length >= currentAction.max_entries
    ) {
      showNotification("Congratulations!  You've completed this action.");
      clearCurrentAction();
    }
  }, [contacts, currentAction, clearCurrentAction, actionContacts.length]);

  const contactElem = (contact: Contact | null) => {
    if (contact) {
      return (
        <div style={{ textAlign: "center", paddingTop: "10px" }}>
          You added {contact.first_name} {contact.last_name}.
        </div>
      );
    }
    return (
      <ContactForm
        currentAction={currentAction}
        refreshActionState={refreshActionState}
      />
    );
  };

  return (
    <TourProvider
      steps={steps}
      disableInteraction
      showCloseButton
      styles={{
        close: (base) => ({ ...base, color: "blue", width: "15px" }),
      }}
    >
      <Tour
        tour_enabled={tourEnabled}
        section="contact_form"
        user_first_name={currentUser?.first_name}
        message="Click here to tour the contact form."
      />

      <div data-testid="contact-form-container" style={{ padding: "10px" }}>
        <FormTitle action={currentAction} />
        {contactElem(contact)}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "30px",
          }}
        >
          <div style={{ marginRight: "20px" }}>
            <Button
              onClick={() => setPage((curPage) => curPage - 1)}
              disabled={page === 1}
            >
              Prev
            </Button>
          </div>
          <Button
            onClick={() => setPage((curPage) => curPage + 1)}
            disabled={page === contacts.length + 1}
          >
            Next
          </Button>
        </div>
      </div>
    </TourProvider>
  );
}
