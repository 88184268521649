import { UserType } from '../users/UserTypes'
import APIService from './ApiService'

type ValidResponse = {
    agreement: {
        url: string
    }
}

type ErrorResponse = {
    errors: string[]
}
type APIResponse = ValidResponse | ErrorResponse

export default class EnrollmentService {
    private static async fetchUrl(
        url: string,
        currentUser: UserType | null,
        callback: (url: string) => void
    ) {
        if (currentUser == null) {
            return Promise.resolve()
        }
        type GetSignNowUrlResponse = {
            agreement: {
                url: string
            }
            errors: string[]
        }
        const respData = await APIService.getAsJson<GetSignNowUrlResponse>(url)
        if (respData) {
            if (respData.agreement) {
                callback(respData.agreement.url)
            } else {
                console.log(respData.errors)
            }
        } else {
            console.log('no reponse data')
        }
        return Promise.resolve()
    }
    static async getSignNowUrl(
        currentUser: UserType | null,
        callback: (url: string) => void
    ) {
        if (currentUser?.contractor_agreement_status !== 'VALID') {
            await this.fetchUrl('users/sign_now_url', currentUser, callback)
        }
        return Promise.resolve()
    }

    static async getDotsUrl(
        currentUser: UserType | null,
        callback: (url: string) => void
    ) {
        if (currentUser?.user_dots_onboarding_status !== 'VALID') {
            await this.fetchUrl('users/dots_url', currentUser, callback)
        }
        return Promise.resolve()
    }
}
