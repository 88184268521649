import { Button, Container, Group, Paper, Stepper, Text } from "@mantine/core";

import { useDisclosure } from "@mantine/hooks";
import { useState } from "react";
import ReactPixel from "react-facebook-pixel";
import { useNavigate } from "react-router-dom";
import { useLogMetaData } from "../Contexts";
import AuthService, {
  FinalRegisterParams,
  InitialRegisterParams,
} from "../services/AuthService";
import UserLoginModal from "./UserLoginModal";
import UserRegisterFirst from "./UserRegisterFirst";
import { UserRegisterSecond } from "./UserRegisterSecond";
import { UserType } from "./UserTypes";

const REACT_PIXEL_ID = "1371609620106549";

type UserRegisterProps = {
  login: (user: UserType) => void;
};

export default function UserRegister({ login }: UserRegisterProps) {
  const [opened, { open, close }] = useDisclosure(false);

  ReactPixel.init(REACT_PIXEL_ID);
  ReactPixel.pageView();

  const [active, setActive] = useState(0);
  const logMeta = useLogMetaData();

  const [InitialRegisterParams, SetInitialRegisterParams] =
    useState<InitialRegisterParams>({
      first_name: "",
      middle_name: "",
      last_name: "",
      suffix: "",
      phone: "",
      email: "",
      city: "",
      state: "",
      meta: logMeta,
    });
  const updateInitialRegisterParams = (
    updates: Partial<InitialRegisterParams>
  ) => {
    SetInitialRegisterParams((prevRegisterParams) => ({
      ...prevRegisterParams,
      ...updates,
    }));
  };

  const [FinalRegisterParams, SetFinalRegisterParams] = useState<
    Omit<FinalRegisterParams, "phone">
  >({
    street: "",
    street_2: "",
    zip: "",
    dob: "",
    password: "",
  });

  const updateFinalRegisterParams = (updates: Partial<FinalRegisterParams>) => {
    SetFinalRegisterParams((prevRegisterParams) => ({
      ...prevRegisterParams,
      ...updates,
    }));
  };

  const [firstErrors, setFirstErrors] = useState<string[]>([]);
  const [secondErrors, setSecondErrors] = useState<string[]>([]);

  const navigate = useNavigate();

  const nextStep = () => {
    AuthService.initialRegister(InitialRegisterParams).then((resp) => {
      if (resp.ok) {
        setActive((current) => {
          return current < 1 ? current + 1 : current;
        });
        setFirstErrors([]);
      } else {
        setFirstErrors(resp.val);
      }
    });
  };
  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    AuthService.final_register({
      ...FinalRegisterParams,
      phone: InitialRegisterParams.phone,
    })
      .then((resp) => {
        if (resp.ok) {
          navigate("/login");
        } else {
          setSecondErrors(resp.val);
        }
      })
      .catch((err) => console.log(err));
  };

  const prevStep = () =>
    setActive((current) => (current > 0 ? current - 1 : current));

  return (
    <Container size={1000} my={40}>
      <UserLoginModal isOpen={opened} close={close} login={login} />
      <Paper withBorder shadow="md" p={30} mt={30} radius="md">
        <Stepper active={active}>
          <Stepper.Step label="Step 1" description="Sign Up">
            <UserRegisterFirst
              registerParams={InitialRegisterParams}
              updateRegisterParams={updateInitialRegisterParams}
              errors={firstErrors}
            />
          </Stepper.Step>
          <Stepper.Step label="Step 2" description="Create Your Login">
            <UserRegisterSecond
              registerParams={FinalRegisterParams}
              updateRegisterParams={updateFinalRegisterParams}
              errors={secondErrors}
            />
          </Stepper.Step>
        </Stepper>
        <Group style={{ justifyContent: "flex-end", marginTop: "1.5rem" }}>
          {active !== 0 && (
            <Button variant="default" onClick={prevStep}>
              Back
            </Button>
          )}
          {active !== 1 && <Button onClick={nextStep}>Next step</Button>}
          {active === 1 && <Button onClick={handleSubmit}>Submit</Button>}
        </Group>
      </Paper>
      <Text
        onClick={() => open()}
        style={{ marginTop: "15px", cursor: "pointer" }}
      >
        Already have an account? Log in here
      </Text>
    </Container>
  );
}
