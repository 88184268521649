import {
    Button,
    Container,
    Image,
    Paper,
    Text,
    TextInput,
    Title,
} from '@mantine/core'
import { useContext, useState } from 'react'
import { CurrentProjectContext } from '../Contexts'
import APIService from '../services/ApiService'

export default function RequestPasswordReset() {
    const [identifier, setIdentifier] = useState<string>('')
    const [error, setError] = useState<string>('')
    const [message, setMessage] = useState<string>('')

    const { currentProject } = useContext(CurrentProjectContext)

    const handleSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault()
        setError('')
        setMessage('')
        APIService.create('request_password_reset', { identifier }).then(
            async (response) => {
                if (response.ok) {
                    console.log('success')
                    const responseJson = await response.json()
                    console.log(responseJson)
                    setMessage(responseJson['message'])
                } else {
                    console.log('error')
                    const responseJson = await response.json()
                    console.log(responseJson)
                    setError(responseJson['error'])
                }
            }
        )
    }

    return (
        <Container size={420} my={40}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Image
                    src="/astatic/static/logo_phone.png"
                    alt="Project"
                    style={{ width: '80%', paddingBottom: '40px' }}
                />
            </div>

            <Title style={{ textAlign: 'center' }}>Reset Password</Title>
            <Title order={3} style={{ textAlign: 'center' }}>
                {currentProject?.name}
            </Title>

            <Paper withBorder shadow="md" p={30} mt={30} radius="md">
                <form onSubmit={handleSubmit}>
                    <TextInput
                        id="userIdentifier"
                        value={identifier}
                        label="Email or Phone"
                        required
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setIdentifier(e.target.value)
                        }
                        className="input-field"
                    />
                    {message && (
                        <Text color="green" style={{ textAlign: 'center' }}>
                            {message}
                        </Text>
                    )}
                    {error && (
                        <Text
                            className="error-message"
                            color="red"
                            style={{ textAlign: 'center' }}
                        >
                            {error}
                        </Text>
                    )}
                    <Button
                        type="submit"
                        className="submit-button"
                        fullWidth
                        mt="xl"
                        onClick={handleSubmit}
                    >
                        Submit
                    </Button>
                </form>
            </Paper>
        </Container>
    )
}
