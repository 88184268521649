import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

import getBrowserFingerprint from "get-browser-fingerprint";
import { useGeolocated } from "react-geolocated";
import { Project } from "./projects/ProjectTypes";
import { LogMeta } from "./services/AuthService";
import { UserType } from "./users/UserTypes";

interface UserContextValue {
  currentUser: UserType | null;
  logout: () => void;
  refreshCurrentUser: () => void;
}

export const CurrentUserContext = React.createContext<UserContextValue>({
  currentUser: null,
  refreshCurrentUser: () => {},
  logout: () => {},
});

interface ProjectContextValue {
  currentProject: Project | null;
  setCurrentProject: React.Dispatch<React.SetStateAction<Project | null>>;
}

export const CurrentProjectContext = React.createContext<ProjectContextValue>({
  currentProject: null,
  setCurrentProject: () => {},
});

interface NotificationContextType {
  notification: string | null;
  showNotification: (message: string) => void;
}

const NotificationContext = createContext<NotificationContextType | undefined>(
  undefined
);

export const useNotification = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error(
      "useNotification must be used within a NotificationProvider"
    );
  }
  return context;
};

interface NotificationProviderProps {
  children: ReactNode;
}

export const NotificationProvider = ({
  children,
}: NotificationProviderProps) => {
  const [notification, setNotification] = useState<string | null>(null);

  const showNotification = (message: string) => {
    setNotification(message);
    setTimeout(() => {
      setNotification(null);
    }, 3000);
  };

  return (
    <NotificationContext.Provider value={{ notification, showNotification }}>
      {children}
    </NotificationContext.Provider>
  );
};

interface GeolocationContextType {
  coords: { latitude: number; longitude: number; accuracy: number } | undefined;
  isGeolocationAvailable: boolean;
  isGeolocationEnabled: boolean;
  positionError: any;
}

const GeolocationContext = createContext<GeolocationContextType>({
  coords: undefined,
  isGeolocationAvailable: false,
  isGeolocationEnabled: false,
  positionError: null,
});

export const GeolocationProvider = ({ children }: { children: ReactNode }) => {
  const {
    coords,
    isGeolocationAvailable,
    isGeolocationEnabled,
    positionError,
  } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: true,
    },
    userDecisionTimeout: 5000,
  });

  return (
    <GeolocationContext.Provider
      value={{
        coords,
        isGeolocationAvailable,
        isGeolocationEnabled,
        positionError,
      }}
    >
      {children}
    </GeolocationContext.Provider>
  );
};

export const useGeolocatedProvider = () => {
  const context = useContext(GeolocationContext);
  if (!context) {
    throw new Error(
      "useGeolocatedProvider must be used within a GeolocationProvider"
    );
  }
  return context;
};

interface FingerprintContextType {
  fingerprint: string | null;
  loading: boolean;
}

const FingerprintContext = createContext<FingerprintContextType>({
  fingerprint: null,
  loading: true,
});

export const FingerprintProvider = ({ children }: { children: ReactNode }) => {
  const [fingerprint, setFingerprint] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fingerprint = getBrowserFingerprint();
    setFingerprint(fingerprint);
    setLoading(false);
  }, []);

  return (
    <FingerprintContext.Provider value={{ fingerprint, loading }}>
      {children}
    </FingerprintContext.Provider>
  );
};

export const useFingerprint = () => {
  return useContext(FingerprintContext);
};

interface LogMetaDataContextType {
  geolocation: GeolocationContextType;
  fingerprint: FingerprintContextType;
}

const LogMetaDataContext = createContext<LogMeta>({
  geolocation: {
    latitude: undefined,
    longitude: undefined,
    accuracy: undefined,
  },
  fingerprint: null,
});

export const LogMetaDataProvider = ({ children }: { children: ReactNode }) => {
  const geolocation = useGeolocatedProvider();
  const fingerprint = useFingerprint();

  const providerValue = {
    geolocation: {
      latitude: geolocation.coords?.latitude,
      longitude: geolocation.coords?.longitude,
      accuracy: geolocation.coords?.accuracy,
    },
    fingerprint: fingerprint.fingerprint,
  };
  return (
    <LogMetaDataContext.Provider value={providerValue}>
      {children}
    </LogMetaDataContext.Provider>
  );
};

export const useLogMetaData = () => {
  return useContext(LogMetaDataContext);
};
