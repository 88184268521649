import { Button, Drawer } from "@mantine/core";
import { IconLogout } from "@tabler/icons-react";
import { useContext } from "react";
import { CurrentUserContext } from "../Contexts";
import AuthService from "../services/AuthService";

type SideDrawerProps = {
  isOpen: boolean;
  close: () => void;
};
export default function SideDrawer(props: SideDrawerProps) {
  const { logout } = useContext(CurrentUserContext);

  const logoutHandler = async () => {
    const result = await AuthService.logout();
    if (result) {
      logout();
    } else {
      console.log("error while logging out");
    }
  };

  return (
    <Drawer opened={props.isOpen} onClose={props.close} size={"50%"}>
      <div style={{ textAlign: "center" }}>
        <h2>Menu</h2>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div style={{ width: "50%" }}>
            <Button
              onClick={logoutHandler}
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <IconLogout size={14} />
                <span style={{ marginLeft: "8px" }}>Logout</span>
              </div>
            </Button>
          </div>
        </div>
      </div>
    </Drawer>
  );
}
