import { Err, Ok, Result } from "ts-results";
import { UserType } from "../users/UserTypes";
import APIService from "./ApiService";

type LoginResponse = {
  access_token: string;
  user: UserType;
  error: string;
};

type RegisterResponse = {
  user: UserType;
  access_token: string;
  errors: string[];
};

export type InitialRegisterParams = {
  first_name: string;
  middle_name: string;
  last_name: string;
  suffix: string;
  phone: string;
  email: string;
  city: string;
  state: string;
  meta: LogMeta;
};

export type FinalRegisterParams = {
  dob: string;
  phone: string;
  street: string;
  street_2: string;
  zip: string;
  password: string;
};

type GetUserResponse = {
  user: UserType;
};

export type LogMeta = {
  geolocation: {
    latitude: number | undefined;
    longitude: number | undefined;
    accuracy: number | undefined;
  };
  fingerprint: string | null;
};

class AuthService {
  static async currentUser(): Promise<UserType | null> {
    try {
      const response = await APIService.get("current_user");

      if (!response.ok) {
        throw new Error(`Failed to fetch from endpoint users/current_user`);
      }
      const responseJSON: GetUserResponse = await response.json();
      return responseJSON.user;
    } catch (error) {
      console.error("Error during login check:", error);
      return null;
    }
  }

  static async login(
    identifier: string,
    password: string,
    meta: LogMeta
  ): Promise<Result<UserType, string>> {
    localStorage.removeItem("token");
    return await APIService.create("login", { identifier, password, meta })
      .then((response) => {
        return response.json();
      })
      .then((responseJSON: LoginResponse) => {
        if (responseJSON.error) {
          return Err(responseJSON.error);
        }
        localStorage.setItem("token", responseJSON.access_token);
        return Ok(responseJSON.user);
      });
  }

  static async logout(): Promise<boolean> {
    try {
      const response = await APIService.create("logout", {});

      if (response.ok) {
        localStorage.removeItem("token");
        return true;
      } else {
        console.error("Logout failed:", response);
        return false;
      }
    } catch (error) {
      console.error("Logout error:", error);
      return false;
    }
  }

  static async initialRegister({
    first_name,
    middle_name,
    last_name,
    phone,
    email,
    city,
    state,
    meta,
  }: InitialRegisterParams) {
    try {
      const requestBody = {
        user: {
          first_name,
          middle_name,
          last_name,
          phone,
          email,
          city,
          state,
        },
        meta: meta,
      };
      const response = await APIService.create(
        "users/initial_registration",
        requestBody
      );
      const registerResponse = (await response.json()) as RegisterResponse;

      if (response.ok) {
        return Ok({});
      } else {
        return Err(registerResponse.errors);
      }
    } catch (error) {
      console.error("Registration error:", error);
      return Err(["There was a problem with user registration."]);
    }
  }

  static async final_register({
    dob,
    phone,
    street,
    zip,
    password,
  }: FinalRegisterParams): Promise<
    Result<{ user: UserType; accessToken: string }, string[]>
  > {
    try {
      const requestBody = {
        user: { dob, phone, street, zip, password },
      };

      const response = await APIService.create(
        "users/final_registration",
        requestBody
      );

      const registerResponse = (await response.json()) as RegisterResponse;

      if (response.ok) {
        return Ok({
          user: registerResponse.user,
          accessToken: registerResponse.access_token,
        });
      } else {
        return Err(registerResponse.errors);
      }
    } catch (error) {
      console.error("Registration error:", error);
      return Err(["There was a problem with user registration."]);
    }
  }
}

export default AuthService;
