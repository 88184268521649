import { useContext } from "react";
import { CurrentProjectContext } from "../Contexts";

import { Container, Image, Text, Title } from "@mantine/core";
import UserRegister from "../users/UserRegister";
import { UserType } from "../users/UserTypes";
import HomePageWidget from "./actions/HomePageWidget";

type LandingPageProps = {
  login: (user: UserType) => void;
};

const LandingPage = ({ login }: LandingPageProps) => {
  const { currentProject } = useContext(CurrentProjectContext);
  return (
    <>
      <Container size={800} style={{ margin: "auto", textAlign: "center" }}>
        <Image
          src="/astatic/static/logo_phone.png"
          alt="Project"
          style={{
            maxWidth: "50%",
            display: "block",
            margin: "0 auto",
          }}
        />
        <Title order={1} style={{ marginTop: "20px" }}>
          {currentProject?.name}
        </Title>
        {currentProject !== null && (
          <Text
            size="md"
            style={{ marginTop: "10px" }}
            dangerouslySetInnerHTML={{
              __html: currentProject.description,
            }}
          />
        )}
        <UserRegister login={login} />
      </Container>
      <HomePageWidget />
    </>
  );
};

export default LandingPage;
