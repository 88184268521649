import { Form } from 'react-bootstrap'

type ErrorDisplayParams = {
    errors: Array<string> | null
}

export function ErrorDisplay({ errors }: ErrorDisplayParams) {
    if (errors && errors.length > 0) {
        return (
            <Form.Group>
                <ul>
                    {errors.map((err, index) => (
                        <li key={index}>{err}</li>
                    ))}
                </ul>
            </Form.Group>
        )
    } else {
        return null
    }
}
