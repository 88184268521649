import { Button } from '@mantine/core'

export default function SubmitButton({ disabled }: { disabled?: boolean }) {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '20px',
            }}
        >
            <Button
                type="submit"
                className="submit-button"
                style={{ flex: '1 0 33%', maxWidth: '33%' }}
                mt="xl"
                disabled={disabled}
            >
                Submit
            </Button>
        </div>
    )
}
