import { Input, Textarea, TextInput } from "@mantine/core";
import { IMaskInput } from "react-imask";

export type CustomTextInputParams<T extends Record<string, string>> = {
  label: string;
  placeholder?: string;
  description?: string;
  model_attr: keyof T;
  updateModel: (updates: Partial<T>) => void;
  value: string;
  required?: boolean;
  type?: "text" | "text_area" | "phone" | "dob";
  labelProps?: Record<string, any>;
  pattern?: string;
  autoFocus?: boolean;
};

export const customInputElementOrder: (
  | "label"
  | "description"
  | "input"
  | "error"
)[] = ["label", "input", "description", "error"];
export default function CustomTextInput<T extends Record<string, string>>({
  label,
  placeholder,
  description,
  model_attr,
  updateModel,
  value,
  required,
  type = "text",
  pattern,
  autoFocus,
}: CustomTextInputParams<T>) {
  const handleChange = (value: string) => {
    const updates: Partial<T> = {
      [model_attr]: value,
    } as Partial<T>;

    updateModel(updates);
  };

  const style = {
    width: "100%",
    marginTop: 0,
    marginBottom: 0,
  };

  switch (type) {
    case "text":
      return (
        <div style={{ flex: 1 }} className="form-group">
          <TextInput
            type="text"
            id={model_attr as string}
            placeholder={placeholder}
            description={description}
            label={label}
            value={value}
            onChange={(e) => handleChange(e.target.value)}
            required={required}
            style={style}
            inputWrapperOrder={customInputElementOrder}
            pattern={pattern}
            autoFocus={autoFocus}
          />
        </div>
      );
    case "text_area":
      return (
        <div style={{ flex: 1 }} className="form-group">
          <Textarea
            id={model_attr as string}
            label={label}
            value={value}
            onChange={(e) => handleChange(e.target.value)}
            required={required}
            style={style}
            minRows={4}
            inputWrapperOrder={customInputElementOrder}
          />
        </div>
      );
    case "phone":
      return (
        <Input.Wrapper
          label={label}
          style={{ ...style, width: "50%" }}
          required={required}
          inputWrapperOrder={customInputElementOrder}
        >
          <Input
            id={model_attr as string}
            component={IMaskInput}
            mask="000-000-0000"
            placeholder={placeholder}
            required={required}
            value={value}
            style={{ wrapper: style }}
            onChange={(e) => handleChange(e.currentTarget.value)}
            onInput={(e) => handleChange(e.currentTarget.value)}
          />
        </Input.Wrapper>
      );
    case "dob":
      return (
        <Input.Wrapper
          label={label}
          style={{ ...style }}
          required={required}
          description={description}
          inputWrapperOrder={customInputElementOrder}
        >
          <Input
            id={model_attr as string}
            component={IMaskInput}
            mask="0000-00-00"
            placeholder={placeholder}
            required={required}
            value={value}
            style={{ wrapper: style }}
            onChange={(e) => handleChange(e.currentTarget.value)}
            onInput={(e) => handleChange(e.currentTarget.value)}
          />
        </Input.Wrapper>
      );

    default:
      throw new Error("invalid input type");
  }
}
