import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { UserType } from "../users/UserTypes";

export type ImpersonateProps = {
  login: (user: UserType) => void;
};

export default function Impersonate() {
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  useEffect(() => {
    const token = searchParams.get("token");
    if (token) {
      localStorage.setItem("token", token);
      navigate("/");
    }
  });

  return <p />;
}
