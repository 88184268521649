import {
    Button,
    Container,
    Image,
    Paper,
    Text,
    TextInput,
    Title,
} from '@mantine/core'
import { useContext, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { CurrentProjectContext } from '../Contexts'
import APIService from '../services/ApiService'

export default function ResetPassord() {
    const { currentProject } = useContext(CurrentProjectContext)
    const navigate = useNavigate()

    const [searchParams] = useSearchParams()
    const token = searchParams.get('token')

    const [password, setPassword] = useState<string>('')
    const [error, setError] = useState<string>('')

    const handleSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault()

        setError('')
        APIService.create('password_reset', {
            token: token,
            password: password,
        })
            .then((response) => {
                if (response.ok) {
                    navigate('/login')
                } else {
                    return response.json()
                }
            })
            .then((data) => {
                setError(data['error'])
            })
    }

    return (
        <Container size={420} my={40}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Image
                    src="/astatic/static/logo_phone.png"
                    alt="Project"
                    style={{ width: '80%', paddingBottom: '40px' }}
                />
            </div>

            <Title style={{ textAlign: 'center' }}>Reset Password</Title>
            <Title order={3} style={{ textAlign: 'center' }}>
                {currentProject?.name}
            </Title>

            <Paper withBorder shadow="md" p={30} mt={30} radius="md">
                <form onSubmit={handleSubmit}>
                    <TextInput
                        id="password"
                        value={password}
                        label="New Password"
                        required
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setPassword(e.target.value)
                        }
                        className="input-field"
                    />
                    {error && (
                        <Text
                            className="error-message"
                            color="red"
                            style={{ textAlign: 'center' }}
                        >
                            {error}
                        </Text>
                    )}
                    <Button
                        type="submit"
                        className="submit-button"
                        fullWidth
                        mt="xl"
                        onClick={handleSubmit}
                    >
                        Submit
                    </Button>
                </form>
            </Paper>
        </Container>
    )
}
