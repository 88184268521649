import { Text } from '@mantine/core'
import { Action } from '../../projects/ProjectTypes'
import { UserAction } from '../../user_actions/types'

type AmountEarnedProps = {
    action: Action
    userActions: UserAction[]
}

export default function AmountEarned({
    action,
    userActions,
}: AmountEarnedProps) {
    if (action.completion_type !== 'PER') {
        return null
    }
    const amountEarned = userActions.reduce((total, action) => {
        if (action.amount_earned === null) {
            return total
        }
        return total + parseFloat(action.amount_earned)
    }, 0)
    if (amountEarned === 0) {
        return null
    }
    return (
        <div
            style={{
                justifyContent: 'center',
                display: 'flex',
                marginTop: '10px',
            }}
        >
            <Text>Total Earned: ${amountEarned.toFixed(2)}</Text>
        </div>
    )
}
