import { Text } from "@mantine/core";
import CustomTextInput from "../components/forms/CustomTextInput";
import FormRow from "../components/forms/FormRow";
import StateInput from "../components/forms/StateInput";
import states from "../helpers/states";
import { InitialRegisterParams } from "../services/AuthService";

type UserRegisterFirstProps = {
  updateRegisterParams: (updates: Partial<InitialRegisterParams>) => void;
  registerParams: InitialRegisterParams;
  errors: string[];
};

export default function UserRegisterFirst({
  registerParams,
  updateRegisterParams,
  errors,
}: UserRegisterFirstProps) {
  return (
    <div>
      <FormRow>
        <CustomTextInput
          label="First Name"
          model_attr="first_name"
          value={registerParams.first_name}
          required
          updateModel={updateRegisterParams}
          autoFocus
        />
        <CustomTextInput
          label="Middle Name"
          model_attr="middle_name"
          value={registerParams.middle_name}
          updateModel={updateRegisterParams}
        />

        <CustomTextInput
          label="Last Name"
          model_attr="last_name"
          value={registerParams.last_name}
          required
          updateModel={updateRegisterParams}
        />
        <CustomTextInput
          label="Suffix"
          model_attr="suffix"
          value={registerParams.suffix}
          updateModel={updateRegisterParams}
        />
      </FormRow>
      <FormRow>
        <CustomTextInput
          type="phone"
          label="Phone"
          model_attr="phone"
          value={registerParams.phone}
          required
          updateModel={updateRegisterParams}
          placeholder="Your Phone"
        />
        <CustomTextInput
          label="Email"
          model_attr="email"
          value={registerParams.email}
          updateModel={updateRegisterParams}
          required
        />
      </FormRow>
      <FormRow>
        <CustomTextInput
          label="City"
          model_attr="city"
          value={registerParams.city}
          required
          updateModel={updateRegisterParams}
        />
        <StateInput
          label="State"
          value={registerParams.state}
          model_attr="state"
          states={states}
          updateModel={updateRegisterParams}
          onInput={(e) => {
            updateRegisterParams({ state: e.currentTarget.value });
          }}
          required
        />
      </FormRow>
      {errors && (
        <Text className="error-message" color="red">
          {errors.map((error, index) => (
            <Text key={index} className="error-message" color="red">
              {error}
            </Text>
          ))}
        </Text>
      )}
    </div>
  );
}
