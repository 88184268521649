import { Contact } from "../contacts/ContactTypes";
import { ConversationTemplate } from "../conversations/ConversationTypes";
import { TourStepType } from "../tour_steps/TourStepTypes";

export type CompletionType = "GROUPED" | "PER";

type BaseAction = {
  id: number;
  name: string;
  action_available: boolean;
  description: string;
  pay_amount: number;
  completed: boolean;
  completed_date: Date | null;
  completion_type: CompletionType;
  entries_required: number | null;
  max_entries: number | null;
  state_options: Array<string> | null;
  zip_code_options: Array<string> | null;
  city_options: Array<string> | null;
};

export type CollectContactsAction = BaseAction & {
  type: "COLLECT_CONTACTS";
};

export type ConversationsAction = BaseAction & {
  type: "CONVERSATIONS";
  conversation_template: ConversationTemplate;
};

export function isValidGroupedAction(
  action: BaseAction
): action is BaseAction & {
  completion_type: "GROUPED";
  entries_required: number;
  max_entries: null;
} {
  return (
    action.completion_type === "GROUPED" &&
    action.entries_required !== null &&
    action.max_entries === null
  );
}

export function contactValidForConversationAction(
  contact: Contact,
  action: BaseAction
): boolean {
  const checkOptions = (
    options: Array<string> | null,
    attr: string
  ): boolean => {
    if (options === null || options.length === 0) {
      return true;
    }
    return options
      .map((option) => option.toLowerCase())
      .includes(attr.toLowerCase());
  };
  return (
    contact.available &&
    checkOptions(action.state_options, contact.state) &&
    checkOptions(action.city_options, contact.city) &&
    checkOptions(action.zip_code_options, contact.zip)
  );
}

export function isValidPerAction(action: BaseAction): action is BaseAction & {
  completion_type: "PER";
  entries_required: null;
  max_entries: number;
} {
  return (
    action.completion_type === "PER" &&
    action.entries_required === null &&
    action.max_entries !== null
  );
}
export type Action = CollectContactsAction | ConversationsAction;

export type GetActionResponse = {
  action: Action;
};

export type Project = {
  id: number;
  name: string;
  actions: Array<Action>;
  description: string;
  home_page_enabled: boolean;
  home_page_tour_enabled: boolean;
  tour_steps: Array<TourStepType>;
  show_import_contacts: boolean;
};

export type GetProjectResponse = {
  project: Project;
};
