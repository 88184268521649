import { IconDeviceMobileMessage } from "@tabler/icons-react";

// UserContactSubmission is a type that represents the data that will be collected from the user's contacts.
export interface UserContactSubmission {
  name?: Array<string>;
  email?: string;
  tel?: Array<string>;
  address?: Array<{
    city: string;
    country: string;
    dependentLocality: string;
    organization: string;
    postalCode: string;
    recipient: string;
    region: string;
    sortingCode: string;
    addressLine: Array<string>;
  }>;
}

interface Contacts {
  select(props: string[], opts?: any): Promise<UserContactSubmission[]>;
}

declare global {
  interface Navigator {
    contacts?: Contacts;
  }
}

export interface SingleContactAPICollectorProps {
  callback: (contact: UserContactSubmission) => void;
  disabled: boolean;
  display: boolean;
}

export const SingleContactAPICollector = ({
  callback,
  disabled,
  display,
}: SingleContactAPICollectorProps) => {
  const supported = "contacts" in navigator;

  const getContacts = async function () {
    const props = ["address", "name", "email", "tel"];
    const opts = { multiple: false };

    if (!navigator.contacts) {
      return null;
    }
    const contacts: UserContactSubmission[] = await navigator.contacts.select(
      props,
      opts
    );
    if (contacts.length > 0) {
      callback(contacts[0]);
    }
  };

  if (!supported || !display) {
    return null;
  }
  return (
    <div
      style={{ display: "flex", justifyContent: "center", padding: "10px 0" }}
    >
      <button
        disabled={disabled}
        onClick={getContacts}
        style={{ display: "flex", alignItems: "center" }}
      >
        <IconDeviceMobileMessage style={{ marginRight: "0.5rem" }} /> Add From
        Your Contacts
      </button>
    </div>
  );
};
