import { Err, Ok } from "ts-results";
import { Contact, ContactCreateInput } from "../contacts/ContactTypes";
import APIService from "./ApiService";
import { LogMeta } from "./AuthService";

export interface CreateContactResponse {
  contact: Contact;
  errors: string[];
}
export default class ContactService {
  static async createContact(contact: ContactCreateInput, meta: LogMeta) {
    try {
      const response = await APIService.create("contacts", { contact, meta });

      if (response.ok) {
        const registerResponse =
          (await response.json()) as CreateContactResponse;
        return new Ok(registerResponse.contact); // Ensure the Ok instance is created correctly
      } else {
        const errorResponse = (await response.json()) as CreateContactResponse;
        return new Err(errorResponse.errors); // Create an Err instance if needed
      }
    } catch (error) {
      return new Err(["an error occurred while creating the contact"]);
    }
  }

  static async queryContacts() {
    type queryContactsResponse = {
      contacts: Array<Contact>;
    };
    return APIService.getAsJson<queryContactsResponse>(`contacts/query`).then(
      (response) => {
        return response?.contacts;
      }
    );
  }
}
