import { Card, Grid, Text } from "@mantine/core";
import { useWindowSize } from "@uidotdev/usehooks";
import { Contact } from "../../contacts/ContactTypes";
import { Conversation } from "../../conversations/ConversationTypes";
import {
  Action,
  contactValidForConversationAction,
  isValidGroupedAction,
  isValidPerAction,
} from "../../projects/ProjectTypes";
import { UserAction } from "../../user_actions/types";
import { actionColor } from "../colors";
import ActionProgress from "./ActionProgress";
import AmountEarned from "./AmountEarned";
import { actionComplete, dateCompleted, formattedPayAmount } from "./helpers";
import { HomeActionComponentBody } from "./HomeActionComponentBody";
import HomeActionComponentCompleted from "./HomeActionComponentCompleted";
import HomeActionComponentTitle from "./HomeActionComponentTitle";

type HomeActionComponentProps = {
  className?: string;
  setAction: (action: Action) => void;
  action: Action;
  userActions: Array<UserAction>;
  contacts: Array<Contact>;
  conversations: Array<Conversation>;
};

export default function HomeActionComponent({
  className,
  setAction,
  action,
  userActions,
  contacts,
  conversations,
}: HomeActionComponentProps) {
  const { width } = useWindowSize();
  const isMobile = width ? width < 768 : false;

  // thisActionContacts are contacts for this action
  const thisActionContacts = contacts.filter((contact) => {
    return contact.action_id === action.id;
  });

  // thisActionConversations are conversations for this action
  const thisActionConversations = conversations.filter((conversation) => {
    return conversation.action_id === action.id;
  });

  const thisActionSuccessfulConversations = thisActionConversations.filter(
    (conversation) => {
      return conversation.successful;
    }
  );

  const availableContacts = contacts.filter((contact) => {
    return contactValidForConversationAction(contact, action);
  });

  const complete = () => {
    return actionComplete(action, userActions);
  };

  const maxEntries = () => {
    if (isValidGroupedAction(action)) {
      return action.entries_required;
    }
    if (isValidPerAction(action)) {
      if (action.type === "CONVERSATIONS") {
        return Math.min(
          action.max_entries,
          availableContacts.length + thisActionSuccessfulConversations.length
        );
      }
      if (action.type == "COLLECT_CONTACTS") {
        return action.max_entries;
      }
    }
    throw new Error("Invalid action completion type");
  };

  const entryCount = () => {
    switch (action.type) {
      case "CONVERSATIONS":
        return thisActionSuccessfulConversations.length;
      case "COLLECT_CONTACTS":
        return thisActionContacts.length;
      default:
        throw new Error("Invalid action type");
    }
  };

  const canActivate = () => {
    if (complete()) {
      return false;
    }
    if (
      action.type === "CONVERSATIONS" &&
      availableContacts.length <= thisActionSuccessfulConversations.length
    ) {
      return false;
    }
    return true;
  };

  const body = () => {
    if (complete()) {
      return (
        <>
          <HomeActionComponentBody
            paymentDescription={paymentDescription()}
            description={action.description}
          />

          <HomeActionComponentCompleted
            actionName={action.name}
            actionType={action.type}
            completionDate={dateCompleted(userActions)?.toDateString()}
            payAmount={formattedPayAmount(userActions)}
          />
        </>
      );
    }
    if (
      action.type === "CONVERSATIONS" &&
      availableContacts.length <= thisActionSuccessfulConversations.length
    ) {
      return (
        <div style={{ height: "50%", paddingTop: "70px" }}>
          <Text
            size="sm"
            style={{
              margin: "20px 8px",
              textAlign: "center",
              fontWeight: 500,
            }}
          >
            You don't have any eligible contacts to have this conversation with!
            <br />
            Add more contacts to complete this action.
          </Text>
        </div>
      );
    }

    return (
      <>
        <HomeActionComponentBody
          paymentDescription={paymentDescription()}
          description={action.description}
        />
        <ActionProgress
          entryCount={entryCount()}
          maxEntryCount={maxEntries()}
        />
      </>
    );
  };
  const paymentDescription = () => {
    switch (action.type) {
      case "CONVERSATIONS":
        switch (action.completion_type) {
          case "GROUPED":
            return `Earn $${action.pay_amount} for adding ${action.entries_required} conversations`;
          case "PER":
            return `Earn $${action.pay_amount} for each conversation`;
          default:
            throw new Error("Invalid completion type");
        }
      case "COLLECT_CONTACTS":
        switch (action.completion_type) {
          case "GROUPED":
            return `Earn $${action.pay_amount} for adding ${action.entries_required} contacts`;
          case "PER":
            return `Earn $${action.pay_amount} for each contact`;
          default:
            throw new Error("Invalid completion type");
        }
      default:
        throw new Error("Invalid action type");
    }
  };
  return (
    <Grid.Col
      style={{
        cursor: canActivate() ? "pointer" : "default",
        display: "flex",
        flexDirection: "column",
        padding: "0px",
        boxSizing: "border-box",
      }}
      span={isMobile ? 12 : 4}
      onClick={canActivate() ? () => setAction(action) : () => {}}
    >
      <Card
        className={`${className} home_action_component`}
        style={{
          height: "100%",
          minHeight: "300px",
          display: "flex",
          flexDirection: "column",
          margin: "5px",
          border: "5px solid",
          borderColor: actionColor(action.completion_type),
          borderRadius: "10px",
          padding: "0px",
        }}
        shadow="sm"
      >
        <HomeActionComponentTitle
          actionName={action.name}
          actionType={action.type}
          completionType={action.completion_type}
        />
        <Text
          style={{
            backgroundColor: actionColor(action.completion_type),
            textAlign: "center",
            paddingTop: "5px",
          }}
          className="payment"
        >
          {paymentDescription()}
        </Text>
        <AmountEarned userActions={userActions} action={action} />
        {body()}
      </Card>
    </Grid.Col>
  );
}
