import { Container, Paper, Title } from "@mantine/core";
import { TourProvider, useTour } from "@reactour/tour";
import { useCallback, useEffect, useState } from "react";
import { Contact } from "../contacts/ContactTypes";
import { Conversation } from "../conversations/ConversationTypes";
import { Action, Project } from "../projects/ProjectTypes";
import APIService from "../services/ApiService";
import ContactService from "../services/ContactService";
import ConversationService from "../services/ConversationService";
import EnrollmentService from "../services/EnrollmentsService";
import { UserType } from "../users/UserTypes";
import { UserAction } from "../user_actions/types";
import ActionGrid from "./ActionGrid";
import ActionContainer from "./actions/ActionContainer";
import Header from "./Header";
import SideDrawer from "./SideDrawer";
import SignNow from "./SignNow";

function Home({
  currentProject,
  currentUser,
}: {
  currentUser: UserType;
  currentProject: Project;
}) {
  const [currentAction, setCurrentAction] = useState<Action | null>();
  const [userActions, setUserActions] = useState<UserAction[]>([]);
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [signNowURL, setSignNowURL] = useState<string>("");
  const [dotsURL, setDotsURL] = useState<string>("");
  const [sideDrawerOpen, setSideDrawerOpen] = useState(false);
  const { steps } = useTour();

  const refreshContacts = useCallback(async () => {
    try {
      const contacts = await ContactService.queryContacts();
      if (contacts) {
        setContacts(contacts);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  const refreshConversations = useCallback(async () => {
    try {
      const response = await ConversationService.getConversations();
      if (response.ok) {
        setConversations(response.val.conversations);
      } else {
        console.error(response.val);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  const refreshUserActions = useCallback(async () => {
    try {
      type GetUserActionsResponse = {
        user_actions: UserAction[];
      };
      const resp = await APIService.getAsJson<GetUserActionsResponse>(
        "user_actions"
      );
      if (resp?.user_actions) {
        setUserActions(resp.user_actions);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  const refreshActionState = useCallback(async () => {
    await Promise.all([
      refreshContacts(),
      refreshConversations(),
      refreshUserActions(),
    ]);
  }, [refreshContacts, refreshConversations, refreshUserActions]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([
          refreshActionState(),
          EnrollmentService.getSignNowUrl(currentUser, setSignNowURL),
          EnrollmentService.getDotsUrl(currentUser, setDotsURL),
        ]);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [refreshActionState, currentUser, setSignNowURL, setDotsURL]);

  const clearCurrentAction = () => {
    setCurrentAction(null);
  };

  const availableActions = (currentUser: UserType) => {
    if (!currentUser.can_see_actions) return [];
    return currentProject.actions.filter((action) => action.action_available);
  };

  const mainContent = () => {
    if (currentProject.home_page_enabled !== true) {
      return (
        <div
          style={{
            height: "100vh",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Title>
            {
              "Thank you for registering! We're getting started and someone will reach out soon!"
            }
          </Title>
        </div>
      );
    }
    if (
      currentUser.contractor_agreement_status !== "VALID" &&
      signNowURL !== ""
    ) {
      return <SignNow url={signNowURL} />;
    }
    if (currentUser.user_dots_onboarding_status !== "VALID" && dotsURL !== "") {
      return <SignNow url={dotsURL} />;
    }
    if (currentAction) {
      return (
        <ActionContainer
          currentAction={currentAction}
          currentUser={currentUser}
          clearCurrentAction={clearCurrentAction}
          contacts={contacts}
          conversations={conversations}
          refreshActionState={refreshActionState}
          tourEnabled={currentProject.home_page_tour_enabled}
        />
      );
    }
    return (
      <ActionGrid
        userActions={userActions}
        setCurrentAction={setCurrentAction}
        projectActions={availableActions(currentUser)}
        contacts={contacts}
        conversations={conversations}
        currentUser={currentUser}
      />
    );
  };
  return (
    <TourProvider steps={steps} disableInteraction>
      <Container style={{ minHeight: "100vh" }}>
        <Header
          setCurrentAction={setCurrentAction}
          openSideDrawer={() => {
            setSideDrawerOpen(true);
          }}
        />
        <SideDrawer
          isOpen={sideDrawerOpen}
          close={() => {
            setSideDrawerOpen(false);
          }}
        />
        <Paper shadow="sm" style={{ minHeight: "100vh" }}>
          {mainContent()}
        </Paper>
      </Container>
    </TourProvider>
  );
}

export default Home;
