import { Button, Paper, Text, TextInput } from "@mantine/core";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import FormRow from "../components/forms/FormRow";
import { useLogMetaData } from "../Contexts";
import AuthService from "../services/AuthService";
import { UserType } from "./UserTypes";

type UserLoginFormProps = {
  login: (user: UserType) => void;
};

export default function UserLoginForm({ login }: UserLoginFormProps) {
  const [identifier, setIdentifier] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string>("");

  const logMeta = useLogMetaData();

  const navigate = useNavigate();

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    const loginResponse = await AuthService.login(
      identifier,
      password,
      logMeta
    );
    if (loginResponse.ok) {
      login(loginResponse.val);
      navigate("/");
    } else {
      setError(loginResponse.val);
    }
  };

  return (
    <Paper withBorder shadow="md" p={30} mt={30} radius="md">
      <form onSubmit={handleSubmit}>
        <FormRow>
          <TextInput
            label={"Phone or Email"}
            value={identifier}
            required
            style={{ flex: 1 }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setIdentifier(e.target.value)
            }
          />
        </FormRow>
        <FormRow>
          <TextInput
            type="password"
            label={"Password"}
            required
            value={password}
            style={{ flex: 1 }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setPassword(e.target.value)
            }
          />
        </FormRow>
        {error && (
          <Text className="error-message" color="red">
            {error}
          </Text>
        )}
        <Button type="submit" className="submit-button" fullWidth mt="xl">
          Login
        </Button>
      </form>
    </Paper>
  );
}
