import React, { useEffect, useState } from "react";

import { Container, MantineProvider } from "@mantine/core";
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
} from "react-router-dom";
import "./App.css";

import {
  CurrentProjectContext,
  CurrentUserContext,
  FingerprintProvider,
  GeolocationProvider,
  LogMetaDataProvider,
  NotificationProvider,
} from "../Contexts";
import { Project } from "../projects/ProjectTypes";
import AuthService from "../services/AuthService";
import ProjectService from "../services/ProjectService";
import UserLogin from "../users/UserLogin";
import { UserType } from "../users/UserTypes";
import Home from "./Home";
import Impersonate from "./Impersonate";
import LandingPage from "./LandingPage";
import RequestPasswordReset from "./RequestPasswordReset";
import ResetPassword from "./ResetPassword";

import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";

function isUser(user: UserType | null): user is UserType {
  return user !== null && user !== undefined;
}
const PrivateWrapper: React.FC<{
  currentUser: UserType | null;
  loading: boolean;
}> = ({ currentUser, loading }) => {
  if (loading) {
    return <div>Loading...</div>;
  }
  return isUser(currentUser) ? <Outlet /> : <Navigate to="/register" />;
};

const LoggedOutOnlyWrapper: React.FC<{
  currentUser: UserType | null;
}> = ({ currentUser }) => {
  return isUser(currentUser) ? <Navigate to="/" /> : <Outlet />;
};

function App() {
  const [currentUser, setCurrentUser] = useState<UserType | null>(null);
  const [currentProject, setCurrentProject] = useState<Project | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const refreshCurrentUser = async () => {
    try {
      const user = await AuthService.currentUser();
      setCurrentUser(user);
    } catch (error) {
      console.error("Error refreshing current user:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [user, project] = await Promise.all([
          AuthService.currentUser(),
          ProjectService.getDefaultProject(),
        ]);

        setCurrentUser(user);
        setCurrentProject(project);
      } catch (error) {
        console.error("Error fetching user or default project:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const login = async (user: UserType) => {
    setCurrentUser(user);
  };

  const router = createBrowserRouter([
    {
      path: "/",
      element: <PrivateWrapper loading={loading} currentUser={currentUser} />,
      children: [
        {
          path: "",
          element:
            currentProject && currentUser ? (
              <Home currentProject={currentProject} currentUser={currentUser} />
            ) : (
              <div>Loading...</div>
            ),
        },
      ],
    },
    {
      path: "/impersonate",
      element: <Impersonate />,
    },
    {
      path: "/",
      element: <LoggedOutOnlyWrapper currentUser={currentUser} />,
      children: [
        {
          path: "login",
          element: <UserLogin login={login} />,
        },
        {
          path: "register",
          element: <LandingPage login={login} />,
        },
        {
          path: "request_password_reset",
          element: <RequestPasswordReset />,
        },
        {
          path: "reset_password",
          element: <ResetPassword />,
        },
      ],
    },
  ]);

  if (loading) {
    return <div> Loading...</div>;
  }

  const logout = () => {
    window.location.href = "/login";
    localStorage.clear();
  };

  return (
    <MantineProvider defaultColorScheme="dark">
      <NotificationProvider>
        <GeolocationProvider>
          <FingerprintProvider>
            <LogMetaDataProvider>
              <Container fluid>
                <CurrentUserContext.Provider
                  value={{ currentUser, logout, refreshCurrentUser }}
                >
                  <CurrentProjectContext.Provider
                    value={{ currentProject, setCurrentProject }}
                  >
                    <RouterProvider router={router} />
                  </CurrentProjectContext.Provider>
                </CurrentUserContext.Provider>
              </Container>
            </LogMetaDataProvider>
          </FingerprintProvider>
        </GeolocationProvider>
      </NotificationProvider>
    </MantineProvider>
  );
}

export default App;
