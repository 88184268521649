import { Grid } from "@mantine/core";
import { useContext } from "react";
import { Contact } from "../contacts/ContactTypes";
import { CurrentProjectContext } from "../Contexts";
import { Conversation } from "../conversations/ConversationTypes";
import { Action } from "../projects/ProjectTypes";
import { UserType } from "../users/UserTypes";
import { UserAction } from "../user_actions/types";
import HomeActionComponent from "./actions/HomeActionComponent";
import NotificationDisplay from "./NotificationDisplay";
import Tour from "./Tour";

type ActionGridProps = {
  projectActions: Action[];
  setCurrentAction: (action: Action | null) => void;
  userActions: UserAction[];
  contacts: Contact[];
  conversations: Conversation[];
  currentUser: UserType;
};
function ActionGrid({
  projectActions,
  setCurrentAction,
  userActions,
  contacts,
  conversations,
  currentUser,
}: ActionGridProps) {
  const { currentProject } = useContext(CurrentProjectContext);

  return (
    <>
      <Tour
        tour_enabled={currentProject?.home_page_tour_enabled == true}
        section="home"
        user_first_name={currentUser.first_name}
        message="Click here to tour your friend-to-friend dashboard."
      />
      <NotificationDisplay />
      <Grid
        gutter="lg"
        style={{
          display: "flex",
          justifyContent: "flex-start",
          minHeight: "100vh",
          padding: "10px",
        }}
      >
        {projectActions.map((projectAction, i) => {
          const className = `${projectAction.type.toLowerCase()}_action`;
          return (
            <HomeActionComponent
              className={className}
              key={projectAction.id}
              action={projectAction}
              setAction={setCurrentAction}
              userActions={userActions.filter(
                (user_action) => user_action.action_id === projectAction.id
              )}
              contacts={contacts}
              conversations={conversations}
            />
          );
        })}
      </Grid>
    </>
  );
}

export default ActionGrid;
