import { Alert } from "@mantine/core";
import { StepType, useTour } from "@reactour/tour";
import { useEffect, useState } from "react";
import TourStepsService from "../services/TourStepsService";

type TourProps = {
  tour_enabled: boolean;
  section: string;
  user_first_name: string;
  message: string;
};

function Tour({ tour_enabled, section, user_first_name, message }: TourProps) {
  const [tourStepsLoading, setTourStepsLoading] = useState<boolean>(true);
  const [open, setOpen] = useState<boolean>(true);
  const { steps, setSteps, setIsOpen, currentStep, setCurrentStep } = useTour();

  useEffect(() => {
    if (setSteps === undefined || !tour_enabled) return;

    let isMounted = true;

    TourStepsService.getTourSteps(section)
      .then((tour_steps) => {
        if (isMounted && tour_steps !== undefined) {
          const steps: Array<StepType> = tour_steps.map((step) => ({
            selector: ".xxx",
            content: (_: any) => (
              <div
                style={{
                  textAlign: "center",
                  maxWidth: "100%",
                  margin: "0 auto",
                  color: "black",
                }}
              >
                {step.title && (
                  <div
                    style={{
                      width: "100%",
                      backgroundColor: "#4a90e2",
                      marginBottom: "15px",
                    }}
                  >
                    <b>{step.title}</b>
                  </div>
                )}
                <div
                  dangerouslySetInnerHTML={{
                    __html: step.anchor_not_found_text,
                  }}
                />
                {step.image_url && (
                  <img
                    src={step.image_url}
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                )}
              </div>
            ),
            position: "center",
          }));
          return steps;
        }
        return [];
      })
      .then((steps) => {
        if (isMounted) {
          setSteps(steps);
          setTourStepsLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching tour steps:", error);
        setSteps([]);
        setTourStepsLoading(false);
      });

    return () => {
      isMounted = false;
    };
  }, [setSteps, tour_enabled, section]);

  if (tourStepsLoading || !open || steps.length == 0) return null;

  const title = (
    <div style={{ color: "white" }}>Welcome {user_first_name}!</div>
  );

  return (
    <Alert
      title={title}
      withCloseButton
      onClose={() => {
        setOpen(false);
      }}
    >
      <div
        onClick={() => {
          if (currentStep === steps.length - 1) {
            setCurrentStep(0);
          }
          setIsOpen(true);
        }}
        style={{ cursor: "pointer", color: "lightblue" }}
      >
        {message}
      </div>
    </Alert>
  );
}

export default Tour;
