import { Progress, Text } from "@mantine/core";

type ActionProgressProps = {
  entryCount: number;
  maxEntryCount: number;
};

export default function ActionProgress({
  entryCount,
  maxEntryCount,
}: ActionProgressProps) {
  return (
    <div
      style={{
        width: "100%",
        paddingTop: "15px",
        margin: "20px",
      }}
      className="progress"
    >
      <Text size="sm" style={{ paddingBottom: "10px", fontWeight: 500 }}>
        Progress: {entryCount} / {maxEntryCount}
      </Text>
      <Progress
        style={{ marginRight: "40px" }}
        value={(entryCount / maxEntryCount) * 100}
      />
    </div>
  );
}
