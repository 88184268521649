import { Title } from '@mantine/core'
import { Action } from '../../projects/ProjectTypes'

export default function FormTitle({ action }: { action: Action }) {
    return (
        <>
            <Title style={{ marginBottom: '10px', textAlign: 'center' }}>
                {action.name}
            </Title>
            <Title
                order={3}
                style={{ marginBottom: '20px', textAlign: 'center' }}
            >
                <div dangerouslySetInnerHTML={{ __html: action.description }} />
            </Title>
        </>
    )
}
