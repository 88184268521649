import { Card, Spoiler, Text } from "@mantine/core";
import { useRef } from "react";

type HomeActionComponentBodyProps = {
  paymentDescription: string;
  description: string;
};
export function HomeActionComponentBody({
  paymentDescription,
  description,
}: HomeActionComponentBodyProps) {
  const spoilerControlRef = useRef<HTMLButtonElement>(null);

  const handleSpoilerClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.stopPropagation();

    console.log("Spoiler activated");
  };

  return (
    <Card.Section inheritPadding style={{ flexGrow: 1 }}>
      <Spoiler
        maxHeight={50}
        showLabel="Show more"
        hideLabel="Hide"
        controlRef={spoilerControlRef}
        onClick={handleSpoilerClick}
        style={{
          cursor: "default",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Text
          style={{ textAlign: "center" }}
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />
      </Spoiler>
    </Card.Section>
  );
}
