import { ComboboxData, Select, SelectProps } from "@mantine/core";
import CustomTextInput, {
  customInputElementOrder,
  CustomTextInputParams,
} from "./CustomTextInput";

type CustomInputWithOptionsParams<T extends Record<string, string>> =
  CustomTextInputParams<T> & {
    options: ComboboxData | null;
    placeholder?: string;
    selectProps?: SelectProps;
  };

export default function ContactInputWithOptions<
  T extends Record<string, string>
>(props: CustomInputWithOptionsParams<T>) {
  const { placeholder, selectProps } = props;

  if (props.options && props.options.length > 0) {
    const handleChange = (newValue: string | null) => {
      const updates: Partial<T> = {
        [props.model_attr]: newValue || "",
      } as Partial<T>;

      props.updateModel(updates);
    };

    return (
      <div style={{ flex: 1 }} className="form-group">
        <Select
          id={props.model_attr as string}
          value={props.value}
          label={props.label}
          data={props.options}
          onChange={handleChange}
          required={props.required}
          placeholder={placeholder}
          inputWrapperOrder={customInputElementOrder}
          searchable
          style={{
            width: "100%",
          }}
          {...selectProps}
        />
      </div>
    );
  } else {
    const { options, ...otherProps } = props;
    return <CustomTextInput {...otherProps} />;
  }
}
