import { Paper, Select, TextInput } from "@mantine/core";
import { DateInput, DateValue } from "@mantine/dates";
import { ChangeEvent, useState } from "react";
import { Contact } from "../../contacts/ContactTypes";
import { useLogMetaData, useNotification } from "../../Contexts";
import {
  ContactConversationInput,
  ConversationTemplateField,
} from "../../conversations/ConversationTypes";
import { ConversationsAction } from "../../projects/ProjectTypes";
import ConversationService from "../../services/ConversationService";
import { ErrorDisplay } from "../ErrorDisplay";
import CustomInputWithOptions from "../forms/CustomInputWithOptions";
import CustomTextInput, {
  customInputElementOrder,
} from "../forms/CustomTextInput";
import FormRow from "../forms/FormRow";
import "./ConversationForm.css";
import SubmitButton from "./SubmitButton";

type ConversationFormProps = {
  currentAction: ConversationsAction;
  contact: Contact;
  completeCallback: () => void;
};

const resultOptions: Array<{
  label: string;
  successful: boolean;
  value: string;
}> = [
  {
    label: "Please choose one",
    successful: false,
    value: "0",
  },
  {
    label: "Yes, I spoke to them",
    successful: true,
    value: "1",
  },
  {
    label: "No, I didn't speak to them",
    successful: false,
    value: "2",
  },
];

const contactMethodOptions = [
  {
    label: "Please choose one",
    value: "",
  },
  {
    label: "Phone",
    value: "PHONE",
  },
  {
    label: "SMS/Text",
    value: "SMS",
  },
  {
    label: "Email",
    value: "EMAIL",
  },
  {
    label: "In Person",
    value: "IN_PERSON",
  },
];

export default function ConversationForm({
  currentAction,
  contact,
  completeCallback,
}: ConversationFormProps) {
  const initialCustomFieldValues = () => {
    let result: Record<number, string> = {};
    currentAction.conversation_template.fields.forEach((field) => {
      if (field.type === "MULTIPLE_CHOICE") {
        result[field.field_order] = "";
      }
    });
    return result;
  };
  const [conversation, setConversation] = useState<ContactConversationInput>({
    contact_id: contact.id,
    action_id: currentAction.id,
    date_canvassed: new Date(),
    successful: false,
    result: "0",
    contact_method: "",
    feedback: "",
    custom_field_values: initialCustomFieldValues(),
  });
  const [resultID, setResultID] = useState<string>("0");
  const [submitting, setSubmitting] = useState<boolean>(false);
  const { showNotification } = useNotification();

  const logMeta = useLogMetaData();

  const updateConversation = (updates: Partial<ContactConversationInput>) => {
    setConversation((prevConversation) => ({
      ...prevConversation,
      ...updates,
    }));
  };

  const handleCustomTextChange = (field_order: number, value: string) => {
    setConversation((prevConversation) => {
      const updatedCustomFieldValues = {
        ...prevConversation.custom_field_values,
        [field_order]: value,
      };

      return {
        ...prevConversation,
        custom_field_values: updatedCustomFieldValues,
      };
    });
  };
  const handleCustomSelectChange = (
    field_order: number,
    value: string | null
  ) => {
    if (value != null) {
      setConversation((prevConversation) => {
        const updatedCustomFieldValues = {
          ...prevConversation.custom_field_values,
          [field_order]: value,
        };
        return {
          ...prevConversation,
          custom_field_values: updatedCustomFieldValues,
        };
      });
    }
  };

  const [errors, setErrors] = useState<string[]>([]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    setSubmitting(true);
    setErrors([]);
    e.preventDefault();
    ConversationService.createConversation(conversation, logMeta)
      .then((resp) => {
        if (resp.ok) {
          if (conversation.successful) {
            console.log("conversation successful");
            showNotification("You've successfully logged a conversation!");
          } else {
            console.log("conversation not successful");

            showNotification(
              "Looks like this person wasn't available. You can try to reach them again later to complete this task."
            );
          }
          completeCallback();
        } else {
          setErrors(resp.val);
        }
      })
      .catch((err) => setErrors([err]))
      .finally(() => setSubmitting(false));
  };

  const handleResultChange = (value: string | null) => {
    if (value !== null) {
      const resultValue = resultOptions.find(
        (option) => option.value === value
      );

      if (resultValue !== undefined) {
        updateConversation({
          successful: resultValue.successful,
          result: resultValue.label,
        });
        setResultID(resultValue.value);
      } else {
        console.log("resultValue is undefined");
      }
    }
  };

  const renderCustomField = (field: ConversationTemplateField) => {
    switch (field.type) {
      case "TEXT":
        return (
          <FormRow>
            <div
              key={field.id}
              dangerouslySetInnerHTML={{
                __html: field.text_value,
              }}
            />
          </FormRow>
        );
      case "TEXT_INPUT":
        return (
          <FormRow>
            <TextInput
              type="text"
              id={`custom-field-${field.field_order}`}
              label={
                <span
                  dangerouslySetInnerHTML={{
                    __html: field.text_value,
                  }}
                />
              }
              value={conversation.custom_field_values[field.field_order]}
              style={{
                width: "100%",
              }}
              inputWrapperOrder={customInputElementOrder}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleCustomTextChange(field.field_order, e.target.value)
              }
              required={conversation.successful}
              disabled={!conversation.successful}
            />
          </FormRow>
        );
      case "MULTIPLE_CHOICE":
        const options = field.multiple_choice_options
          .map((option) => ({
            label: option,
            value: option,
          }))
          .concat({ label: "Please choose one", value: "" });

        return (
          <FormRow>
            <Select
              id={`custom-field-${field.field_order}`}
              value={conversation.custom_field_values[field.field_order]}
              label={
                <span
                  dangerouslySetInnerHTML={{
                    __html: field.text_value,
                  }}
                />
              }
              data={options}
              inputWrapperOrder={customInputElementOrder}
              searchable
              onChange={(value) =>
                handleCustomSelectChange(field.field_order, value)
              }
              style={{
                width: "100%",
              }}
              required={conversation.successful}
              disabled={!conversation.successful}
            />
          </FormRow>
        );
      default:
        return null;
    }
  };

  const customFields = () => {
    return currentAction.conversation_template.fields.map((field) => {
      return renderCustomField(field);
    });
  };
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  var minDate = new Date();
  minDate.setDate(today.getDate() - 5);

  return (
    <Paper className="form-container">
      <form
        onSubmit={handleSubmit}
        style={{ display: "flex", flexDirection: "column" }}
      >
        <FormRow>
          <div style={{ flex: 1 }} className="form-group">
            <DateInput
              label="Date"
              value={conversation.date_canvassed}
              type="text"
              onChange={(value: DateValue) => {
                if (value) {
                  updateConversation({
                    date_canvassed: value,
                  });
                }
              }}
              minDate={minDate}
              maxDate={today}
              required
            />
          </div>
          <div>
            <CustomInputWithOptions
              label="Contact Method"
              value={conversation.contact_method}
              model_attr="contact_method"
              options={contactMethodOptions}
              updateModel={updateConversation}
              required
            />
          </div>
          <div style={{ flex: "1 1 15%" }} className="form-group">
            <Select
              id={"contact_method"}
              value={resultID}
              label={"Were you able to get in touch with them?"}
              data={resultOptions}
              onChange={handleResultChange}
              required
            />
          </div>
        </FormRow>
        {customFields()}
        <FormRow>
          <CustomTextInput
            label="Please provide more details about your conversation"
            value={conversation.feedback}
            model_attr="feedback"
            type="text_area"
            updateModel={updateConversation}
            required={conversation.successful}
          />
        </FormRow>
        <ErrorDisplay errors={errors} />
        <SubmitButton disabled={submitting} />
      </form>
    </Paper>
  );
}
