import { Err, Ok } from "ts-results";
import { UserContactSubmission } from "../components/SingleContactAPICollector";
import { ContactImport } from "../contact_imports/ContactImportTypes";
import APIService from "./ApiService";

export interface CreateContactImportResponse {
  contact_import: ContactImport;
  errors: string[];
}
export default class ContactImportService {
  static async createContactImport(
    contact_import: UserContactSubmission,
    project_action_id: number
  ) {
    try {
      const params = {
        contact_import: {
          ...contact_import,
          project_action_id: project_action_id,
        },
      };
      const response = await APIService.create("contact_imports", params);

      if (response.ok) {
        const registerResponse =
          (await response.json()) as CreateContactImportResponse;
        return new Ok(registerResponse.contact_import);
      } else {
        const errorResponse =
          (await response.json()) as CreateContactImportResponse;
        return new Err(errorResponse.errors);
      }
    } catch (error) {
      return new Err(["an error occurred while creating the contact"]);
    }
  }
}
