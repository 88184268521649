import { Button, Container, Image, Text, Title } from "@mantine/core";
import { useContext } from "react";

import { useNavigate } from "react-router-dom";
import HomePageWidget from "../components/actions/HomePageWidget";
import { CurrentProjectContext } from "../Contexts";
import UserLoginForm from "./UserLoginForm";
import { UserType } from "./UserTypes";

export type UserLoginProps = {
  login: (user: UserType) => void;
};
function UserLogin({ login }: UserLoginProps) {
  const { currentProject } = useContext(CurrentProjectContext);

  const navigate = useNavigate();

  return (
    <Container size={420} my={40}>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Image
          src="/astatic/static/logo_phone.png"
          alt="Project"
          style={{ width: "80%", paddingBottom: "40px" }}
        />
      </div>
      <Title style={{ textAlign: "center" }}>Welcome!</Title>
      <Title style={{ textAlign: "center" }} order={3}>
        Sign in to {currentProject?.name}
      </Title>
      <UserLoginForm login={login} />
      <Container
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          minHeight: "100px",
        }}
      >
        <Text style={{ marginBottom: "15px" }}>Don’t have an Account?</Text>
        <Button
          onClick={() => navigate("/register")}
          size="lg"
          style={{ marginBottom: "20px" }}
        >
          Sign Up Now!
        </Button>
        <Button
          onClick={() => navigate("/request_password_reset")}
          size="medium"
          style={{ padding: "5px" }}
        >
          Forgot your password?
        </Button>
      </Container>
      <HomePageWidget />
    </Container>
  );
}

export default UserLogin;
