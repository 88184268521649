import { useEffect, useState } from 'react'

export default function HomePageWidget() {
    const [htmlContent, setHtmlContent] = useState('')

    useEffect(() => {
        fetch('/astatic/static/home_page_widget.html')
            .then((response) => response.text())
            .then((content) => setHtmlContent(content))
            .catch((error) => console.error('Error:', error))
    }, [])

    return (
        <div
            style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                paddingTop: '40px',
            }}
            dangerouslySetInnerHTML={{ __html: htmlContent }}
        />
    )
}
