import { Button, Container, Image } from "@mantine/core";
import { useWindowSize } from "@uidotdev/usehooks";
import { useContext } from "react";
import { CurrentProjectContext, CurrentUserContext } from "../Contexts";
import { Action } from "../projects/ProjectTypes";
import "./Header.css";

type HeaderProps = {
  setCurrentAction: React.Dispatch<
    React.SetStateAction<Action | null | undefined>
  >;
  openSideDrawer: () => void;
};

function Header({ openSideDrawer, setCurrentAction }: HeaderProps) {
  const { width } = useWindowSize();
  const isMobile = width ? width < 768 : false;

  const { currentUser } = useContext(CurrentUserContext);
  const { currentProject } = useContext(CurrentProjectContext);

  return (
    <Container className="header-container">
      <div className="home-link" onClick={() => setCurrentAction(null)}>
        <Image
          src="/astatic/static/logo_phone.png"
          alt="Project"
          style={{
            width: "120px",
            display: "block",
            margin: "0 auto",
          }}
        />
        {!isMobile ? (
          <span style={{ paddingLeft: "15px" }}> Dashboard </span>
        ) : null}
      </div>
      {!isMobile ? <div>{currentProject?.name} </div> : null}
      <div className="right-section">
        {!isMobile ? <div>Current User: {currentUser!.first_name}</div> : null}
        <span style={{ paddingLeft: "5px" }}>
          <Button onClick={openSideDrawer}>Menu</Button>
        </span>
      </div>
    </Container>
  );
}

export default Header;
