import { Contact } from '../../contacts/ContactTypes'
import {
    Action,
    isValidGroupedAction,
    isValidPerAction,
} from '../../projects/ProjectTypes'
import { UserAction } from '../../user_actions/types'

export function actionComplete(action: Action, userActions: Array<UserAction>) {
    if (isValidGroupedAction(action)) {
        return completedUserActionCount(userActions) >= 1
    } else if (isValidPerAction(action)) {
        return completedUserActionCount(userActions) >= action.max_entries
    }
}

function completedUserActionCount(userActions: Array<UserAction>) {
    return userActions.filter((action) => action.date_completed !== null).length
}

export function dateCompleted(userActions: Array<UserAction>) {
    return userActions
        .map((action) =>
            action.date_completed ? new Date(action.date_completed) : null
        )
        .filter((date) => date !== null)
        .sort((a, b) => b!.getTime() - a!.getTime())[0]
}

export function formattedPayAmount(userActions: Array<UserAction>) {
    return userActions
        .reduce((total, action) => total + parseFloat(action.amount_earned), 0)
        .toFixed(2)
}

export function contactsForActions(action: Action, contacts: Array<Contact>) {
    return contacts.filter((contact) => contact.action_id === action.id)
}

export function successfulConversationsForAction(
    action: Action,
    conversations: Array<any>
) {
    return conversations.filter(
        (conversation) =>
            conversation.action_id === action.id && conversation.successful
    )
}
