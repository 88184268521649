import { Err, Ok } from "ts-results";
import {
  ContactConversationInput,
  Conversation,
} from "../conversations/ConversationTypes";
import APIService from "./ApiService";
import { LogMeta } from "./AuthService";

interface CreateConversationResponse {
  conversation: Conversation;
  errors: string[];
}

interface QueryConversationsResponse {
  conversations: Conversation[];
  errors: string[];
}

export default class ConversationService {
  static async createConversation(
    conversation: ContactConversationInput,
    meta: LogMeta
  ) {
    try {
      const response = await APIService.create("conversations", {
        conversation,
        meta,
      });

      if (response.ok) {
        const registerResponse =
          (await response.json()) as CreateConversationResponse;
        return Ok(registerResponse.conversation);
      } else {
        const errorResponse =
          (await response.json()) as CreateConversationResponse;
        return Err(errorResponse.errors);
      }
    } catch (error) {
      return Err(["an error occured while creating the conversation"]);
    }
  }

  static async getConversations() {
    try {
      const response = await APIService.getAsJson<QueryConversationsResponse>(
        `conversations`
      );
      if (response) {
        return Ok(response);
      }
      return Err("No reponse");
    } catch (error) {
      return Err(["an error occured while retrieving conversations"]);
    }
  }
}
