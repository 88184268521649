import { Paper } from "@mantine/core";
import { useContext, useState } from "react";
import { ContactCreateInput } from "../../contacts/ContactTypes";
import {
  CurrentProjectContext,
  CurrentUserContext,
  useLogMetaData,
} from "../../Contexts";
import states from "../../helpers/states";
import { CollectContactsAction } from "../../projects/ProjectTypes";
import ContactImportService from "../../services/ContactImportService";
import ContactService from "../../services/ContactService";
import { ErrorDisplay } from "../ErrorDisplay";
import CustomInputWithOptions from "../forms/CustomInputWithOptions";
import CustomTextInput from "../forms/CustomTextInput";
import FormRow from "../forms/FormRow";
import StateInput from "../forms/StateInput";
import {
  SingleContactAPICollector,
  UserContactSubmission,
} from "../SingleContactAPICollector";
import SubmitButton from "./SubmitButton";

type ContactFormProps = {
  currentAction: CollectContactsAction;
  refreshActionState: () => void;
};

const relationship_options = [
  "Parent",
  "Grandparent",
  "Sibling",
  "Child",
  "Grandchild",
  "Cousin",
  "In-Law",
  "Spouse",
  "Significant Other",
  "Family Member",
  "Friend",
  "Coworker",
  "Classmate",
  "Roommate",
  "Neighbor",
  "Church Member",
  "Club Member",
].map((relationship) => {
  return { value: relationship, label: relationship };
});

const dobMonthOpitions = [
  {
    value: "01",
    label: "January",
  },
  {
    value: "02",
    label: "February",
  },
  {
    value: "03",
    label: "March",
  },
  {
    value: "04",
    label: "April",
  },
  {
    value: "05",
    label: "May",
  },
  {
    value: "06",
    label: "June",
  },
  {
    value: "07",
    label: "July",
  },
  {
    value: "08",
    label: "August",
  },
  {
    value: "09",
    label: "September",
  },
  {
    value: "10",
    label: "October",
  },
  {
    value: "11",
    label: "November",
  },
  {
    value: "12",
    label: "December",
  },
];

const gender_options = [
  {
    value: "MALE",
    label: "Male",
  },
  {
    value: "FEMALE",
    label: "Female",
  },
  {
    value: "TRANS",
    label: "Transgender",
  },
  {
    value: "NONBINARY",
    label: "Non-Binary",
  },
];
export default function ContactForm(props: ContactFormProps) {
  const { currentUser } = useContext(CurrentUserContext);
  const [contactImported, setContactImported] = useState(false);
  const { currentProject } = useContext(CurrentProjectContext);

  const state_options = states.map((state) => {
    return { value: state.value, label: state.name };
  });

  const [contact, setContact] = useState<ContactCreateInput>({
    first_name: "",
    nickname: "",
    middle_name: "",
    last_name: "",
    suffix: "",

    age: "",
    dob_year: "",
    dob_month: "",
    dob_day: "",

    gender: "",
    phone: "",
    email: "",
    relationship: "",

    zip: "",
    city: "",
    state: "",
    county: "",
    street: "",
    street_2: "",

    notes: "",
    user_id: currentUser!.id,
    action_id: props.currentAction.id,
  });
  const updateContact = (updates: Partial<ContactCreateInput>) => {
    setContact((prevContact) => ({
      ...prevContact,
      ...updates,
    }));
  };
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);

  const logMeta = useLogMetaData();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSubmitting(true);
    setErrors([]);

    ContactService.createContact(contact, logMeta)
      .then((resp) => {
        if (resp.ok) {
          props.refreshActionState();
        } else {
          setErrors(resp.val);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setSubmitting(false));
  };

  const contactAPICollectorCallback = (
    contact_import: UserContactSubmission
  ) => {
    ContactImportService.createContactImport(
      contact_import,
      props.currentAction.id
    ).then((resp) => {
      if (resp.ok) {
        const name = contact_import.name?.[0]?.split(" ");
        const first_name = name?.[0];
        const last_name = name && name.length > 1 ? name[name.length - 1] : "";
        const address = contact_import.address?.[0];
        const phone = contact_import.tel?.[0];
        const formattedPhone = phone
          ? `${phone.substring(0, 3)}-${phone.substring(
              3,
              6
            )}-${phone.substring(6)}`
          : "";
        updateContact({
          first_name: first_name,
          last_name: last_name,
          email: contact_import.email?.[0],
          phone: phone,
          street: address?.addressLine?.[0],
          city: address?.city,
          state: address?.region,
          zip: address?.postalCode,
        });

        setContactImported(true);
      }
    });
  };

  return (
    <Paper className="form-container">
      <SingleContactAPICollector
        callback={contactAPICollectorCallback}
        disabled={contactImported}
        display={currentProject?.show_import_contacts || false}
      />

      <form
        onSubmit={handleSubmit}
        style={{ display: "flex", flexDirection: "column" }}
      >
        <FormRow>
          <CustomTextInput
            label="First Name"
            model_attr="first_name"
            value={contact.first_name}
            required
            updateModel={updateContact}
          />
          <CustomTextInput
            label="Nickname"
            model_attr="nickname"
            value={contact.nickname}
            updateModel={updateContact}
          />
          <CustomTextInput
            label="Middle Name"
            model_attr="middle_name"
            value={contact.middle_name}
            updateModel={updateContact}
          />
          <CustomTextInput
            label="Last Name"
            model_attr="last_name"
            value={contact.last_name}
            required
            updateModel={updateContact}
          />
          <CustomTextInput
            label="Suffix"
            model_attr="suffix"
            value={contact.suffix}
            updateModel={updateContact}
          />
        </FormRow>
        <FormRow>
          <CustomInputWithOptions
            label="Gender"
            model_attr="gender"
            value={contact.gender}
            options={gender_options}
            required
            placeholder="Select a gender"
            updateModel={updateContact}
          />

          <CustomTextInput
            type="phone"
            label="Phone"
            model_attr="phone"
            value={contact.phone}
            required
            updateModel={updateContact}
            description="Must be formatted XXX-XXX-XXXX"
          />
          <CustomTextInput
            label="Email"
            model_attr="email"
            value={contact.email}
            updateModel={updateContact}
          />
        </FormRow>
        <FormRow>
          <CustomTextInput
            label="Age"
            model_attr="age"
            value={contact.age}
            updateModel={updateContact}
          />

          <CustomTextInput
            label="Date of Birth Year"
            model_attr="dob_year"
            value={contact.dob_year}
            updateModel={updateContact}
          />
          <CustomInputWithOptions
            label="Date of Birth Month"
            value={contact.dob_month}
            model_attr="dob_month"
            placeholder="Select a month"
            options={dobMonthOpitions}
            updateModel={updateContact}
          />
          <CustomTextInput
            label="Date of Birth Day"
            model_attr="dob_day"
            value={contact.dob_day}
            updateModel={updateContact}
          />
        </FormRow>
        <FormRow>
          <CustomTextInput
            label="Street Address"
            value={contact.street}
            model_attr="street"
            updateModel={updateContact}
          />
          <CustomTextInput
            label="Street Address 2"
            value={contact.street_2}
            model_attr="street_2"
            updateModel={updateContact}
          />
        </FormRow>
        <FormRow>
          <StateInput
            label="State"
            value={contact.state}
            model_attr="state"
            states={states}
            placeholder="Select a state"
            updateModel={updateContact}
            onInput={(e) => {
              updateContact({ state: e.currentTarget.value });
            }}
            required
          />
          <CustomTextInput
            label="City"
            value={contact.city}
            model_attr="city"
            updateModel={updateContact}
            required
          />
        </FormRow>
        <FormRow>
          <CustomTextInput
            label="Zip Code"
            value={contact.zip}
            model_attr="zip"
            updateModel={updateContact}
            description="Five Digit Postal Code"
          />
          <CustomTextInput
            label="County"
            value={contact.county}
            model_attr="county"
            updateModel={updateContact}
          />
        </FormRow>
        <FormRow>
          <CustomInputWithOptions
            label="Relationship"
            value={contact.relationship}
            model_attr="relationship"
            options={relationship_options}
            placeholder="Select a relationship"
            updateModel={updateContact}
            required
          />
        </FormRow>
        <FormRow>
          <CustomTextInput
            label="Notes"
            value={contact.notes}
            model_attr="notes"
            type="text_area"
            updateModel={updateContact}
          />
        </FormRow>

        <ErrorDisplay errors={errors} />
        <SubmitButton disabled={submitting} />
      </form>
    </Paper>
  );
}
