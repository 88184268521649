import { Container, Title } from '@mantine/core'
import { useContext, useEffect } from 'react'
import { CurrentUserContext } from '../Contexts'

export default function SignNow({ url }: { url: string }) {
    const { refreshCurrentUser } = useContext(CurrentUserContext)

    useEffect(() => {
        const poll = () => {
            console.log('polling server')
            refreshCurrentUser()
        }
        const intervalId = setInterval(() => {
            poll()
        }, 5000) // Poll every 5 seconds

        // Stop polling after 20 minutes (20 * 60 * 1000 milliseconds)
        setTimeout(
            () => {
                clearInterval(intervalId)
            },
            20 * 60 * 1000
        )

        return () => {
            clearInterval(intervalId)
        }
    }, [refreshCurrentUser])

    return (
        <Container
            style={{
                height: '1000px',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
                paddingTop: '30px',
                textAlign: 'center',
            }}
        >
            <Title
                style={{
                    marginBottom: '20px',
                }}
            >
                Almost done! Provide your tax information and preferred payment
                method so you can get paid for completing actions.
            </Title>
            <iframe
                title={url}
                src={url}
                style={{ height: '100%', width: '100%' }}
            />
        </Container>
    )
}
