import { Drawer, Image, Text, Title } from "@mantine/core";
import { useContext } from "react";
import { CurrentProjectContext } from "../Contexts";
import UserLoginForm from "./UserLoginForm";
import { UserType } from "./UserTypes";

type UserLoginModalProps = {
  isOpen: boolean;
  close: () => void;
  login: (user: UserType) => void;
};

export default function UserLoginModal(props: UserLoginModalProps) {
  const { currentProject } = useContext(CurrentProjectContext);

  return (
    <Drawer opened={props.isOpen} onClose={props.close}>
      <Image
        src="/astatic/static/logo_phone.png"
        alt="Project"
        style={{
          maxWidth: "50%",
          display: "block",
          margin: "0 auto",
        }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Title order={1}>Welcome Back!</Title>
        <Text>{currentProject?.name}</Text>
      </div>
      <UserLoginForm login={props.login} />
    </Drawer>
  );
}
