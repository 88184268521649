import { Image, Text } from "@mantine/core";
import CustomTextInput from "../components/forms/CustomTextInput";
import FormRow from "../components/forms/FormRow";
import { FinalRegisterParams } from "../services/AuthService";

type UserRegisterSecondProps = {
  updateRegisterParams: (updates: Partial<FinalRegisterParams>) => void;
  registerParams: Omit<FinalRegisterParams, "phone">;
  errors: string[];
};

export function UserRegisterSecond({
  updateRegisterParams,
  registerParams,
  errors,
}: UserRegisterSecondProps) {
  return (
    <>
      <FormRow>
        <CustomTextInput
          label="Street Address"
          model_attr="street"
          value={registerParams.street}
          required
          updateModel={updateRegisterParams}
          autoFocus
        />
        <CustomTextInput
          label="Street Address 2"
          model_attr="street_2"
          value={registerParams.street_2}
          updateModel={updateRegisterParams}
        />
        <CustomTextInput
          label="Zip Code"
          model_attr="zip"
          value={registerParams.zip}
          required
          updateModel={updateRegisterParams}
          description="Five Digit Postal Code"
        />
      </FormRow>
      <FormRow>
        <CustomTextInput
          type="dob"
          label="Date of Birth"
          model_attr="dob"
          value={registerParams.dob}
          required
          updateModel={updateRegisterParams}
          description="Must be formatted as YYYY-MM-DD"
        />
      </FormRow>
      <FormRow>
        <CustomTextInput
          label="Password"
          model_attr="password"
          value={registerParams.password}
          required
          updateModel={updateRegisterParams}
          description="Password must contain at least one uppercase letter, one number, and one special character"
        />
      </FormRow>
      {errors && (
        <Text className="error-message" color="red">
          {errors.map((error, index) => (
            <Text key={index} className="error-message" color="red">
              {error}
            </Text>
          ))}
        </Text>
      )}
      <Image
        style={{ height: "1px", width: "1px" }}
        src="https://tags.srv.stackadapt.com/conv?cid=f05algRuDNUbA8irhMmgEf"
      />
      <Image
        style={{ height: "1px", width: "1px" }}
        src="https://tags.srv.stackadapt.com/conv?cid=sMa4OWyOGzuZIJLSJ9TGO3"
      />
      <Image
        style={{ height: "1px", width: "1px" }}
        src="https://tags.srv.stackadapt.com/conv?cid=f05algRuDNUbA8irhMmgEf"
      />
    </>
  );
}
