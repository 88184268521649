import {
    Action,
    GetActionResponse,
    GetProjectResponse,
    Project,
} from '../projects/ProjectTypes'
import APIService from './ApiService'

class ProjectService {
    static async getDefaultProject(): Promise<Project> {
        return APIService.get('default_project')
            .then((response) => {
                if (!response.ok) {
                    throw new Error(
                        `Failed to fetch from endpoint default_project`
                    )
                }
                return response.json()
            })
            .then((responseJSON: GetProjectResponse) => {
                return responseJSON.project
            })
    }

    static async getProject(id: number): Promise<Project> {
        return APIService.getEntity<GetProjectResponse>('project', id).then(
            (apiResponse) => {
                return apiResponse.project
            }
        )
    }

    static async getAction(id: number): Promise<Action> {
        return APIService.getEntity<GetActionResponse>('action', id).then(
            (apiResponse) => {
                return apiResponse.action
            }
        )
    }
}

export default ProjectService
